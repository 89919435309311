export interface Org {
    name:string,
    orgUrl:string,
    logo:string,
    phone:string,
    email:string,
    slogan:string,
    facebookUrl:string,
    linkedinUrl:string,
    twitterUrl:string
    
}

export const AppInfo:Org ={
    name:'SwissDonations',
    orgUrl:'https://www.swissdonations.ch',
    logo:'',
    phone:'',
    email:'info@swissdonations.ch',
    slogan:'Easy and Safe Donations to Swiss Charities',
    linkedinUrl:'https://www.linkedin.com/company/swissdonations',
    twitterUrl:'',
    facebookUrl:'https://www.facebook.com/profile.php?id=100095120823947'
}
