import { IonCol, IonGrid, IonImg, IonRow, IonToolbar } from "@ionic/react";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { translation } from "../models/translation";
import SocialLinks from "./SocialLinks";

const Footer: React.FC = () => {

    let location = useLocation().pathname;

    const siteText = translation();

    const [lan] = useState(siteText.lan);



    const replaceYearInString = (originalString: string) => {
        const now = new Date();
        const genevaOffset = 60; // Geneva is UTC+1 in winter (standard time) and UTC+2 in summer (daylight saving time)
        const genevaTime = now.getTime() + (now.getTimezoneOffset() + genevaOffset) * 60 * 1000;
        const genevaNow = new Date(genevaTime);
        const currentYear = genevaNow.getFullYear();

        return originalString.replace("%&year&%", `${currentYear}`);
    }


    return (
        <IonToolbar>
            <IonGrid className="site_footer">
                <IonRow>
                    <IonCol size="12" className="ion-padding-top">
                        <SocialLinks location={location}></SocialLinks>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                    <IonCol size="auto" className="ion-padding">
                        <p><Link to={lan + "/privacy_policy"}>{siteText.footLnkPrivacyPolTxt}</Link></p>
                    </IonCol>
                    <IonCol size="auto" className="ion-padding">
                        <p><Link to={lan + "/impressum"}>{siteText.footLnkImpressTxt}</Link></p>
                    </IonCol>
                    <IonCol size="auto" className="ion-padding">
                        <p><Link to={lan + "/terms_of_use"}>{siteText.footLnkTouTxt}</Link>
                        </p>
                    </IonCol>
                    <IonCol size="auto" className="ion-padding">
                        <p><Link to={lan + "/faq"}>{siteText.footLnkFaqTxt}</Link>
                        </p>
                    </IonCol>
                    <IonCol size="auto" className="ion-padding">
                        <p>{replaceYearInString(siteText.footLnkCopyRights)}</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="providerLogosWrapper ion-padding-bottom">
                        <IonImg className="providerLogo" src={`${import.meta.env.BASE_URL}images/posfinance_logo.png`} ></IonImg>
                        <IonImg className="providerLogo" src={`${import.meta.env.BASE_URL}images/twint_logo.png`} ></IonImg>
                        <IonImg className="providerLogo" src={`${import.meta.env.BASE_URL}images/mc_symbol_opt_53_3x.png`} ></IonImg>
                        <IonImg className="providerLogo" src={`${import.meta.env.BASE_URL}images/visa.png`} ></IonImg>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
    )

}
export default Footer;