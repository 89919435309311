import { User } from '../models/data';
import Urls from './url';

const authCode = import.meta.env.VITE_APP_KEY;

class AuthServ {
    public user: User | null = null;

    public async getUser() {
        await this.setUser();
        return this.user;
    }

    public async setUser() {
        const value = localStorage.getItem('user');
        if (value) {
            this.user = JSON.parse(value);
            await this.resetToken();
        }
        return this.user;
    }

    public async unSetUser() {
        localStorage.removeItem('user');
        return;
    }

    private async fetchUser(key: any) {
        const response = await fetch(`${Urls('signInUserUrl')}&swWprivateAuthCode=${authCode}`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + key,
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        });

        if (response.ok) {
            const message = await response.json();
            let userData = message.user.data;
            let userMetaData = message.meta;
            console.log(userMetaData)
            let newUser: User = {
                email: userData.user_email,
                username: userData.user_login,
                firstName: userMetaData.first_name,
                lastName: userMetaData.last_name,
                dateOfBirth: userMetaData?.date_of_birth,
                streetNumber:userMetaData?.street_number,
                street:userMetaData?.street,
                city:userMetaData?.city,
                postCode:userMetaData?.postal_code,
                country:userMetaData?.country,
                userLanguage: userMetaData?.user_language,
                isGiftCarduser: userMetaData?.giftcarduser,
                role: userMetaData?.role,
                token: key,
                id: userData.ID,
                code:userMetaData.code
            };
            await this.storeUser(newUser);
        } else {
            return {
                message: null,
                error: response.statusText
            };
        }
    }

    private async storeUser(user: User) {
        localStorage.setItem('user', JSON.stringify(user));
        return;
    }

    public async authenticateUser(data: any) {
        const body = {
            email: data.email,
            password: data.password,
            swWprivateAuthCode: authCode
        };

        const autResp = await fetch(Urls('authenticateUserUrl'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(body)
        });

        if (autResp.ok) {
            const authMessage = await autResp.json();
            await this.fetchUser(authMessage.data.jwt);
            return {
                message: authMessage.data.jwt,
                error: authMessage.statusText
            };
        } else {
            return {
                message: null,
                error: await autResp.json()
            };
        }
    }

    public async registerUser(data: any) {
        let meta_data = { 
            date_of_birth: data.dateOfBirth, 
            language: data.lan,
            street:data.street,
            street_number:data.streetNumber,
            postal_code:data.postCode,
            city:data.city,
            country:data.country,
         };
         console.log(meta_data)
        const body = {
            email: data.email,
            password: data.password,
            first_name: data.firstname,
            user_meta: JSON.stringify(meta_data),
            last_name: data.lastname,
            locale: data.lan + '_CH',
            swWprivateAuthCode: authCode,
        };

        const response = await fetch(Urls('registerUser'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(body)
        });

        if (response.ok) {
            const message = await response.json();
            return message;
        } else {
            return {
                message: null,
                error: response.statusText
            };
        }
    }

    public async resetToken() {
        let result;
        const value = localStorage.getItem('user');
        if (value) {
            let user = JSON.parse(value);
            let key = user.token;
            let body = {
                JWT: key,
                swWprivateAuthCode: authCode
            };

            const resp = await fetch(Urls('resetTokenUrl'), {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(body)
            });

            if (resp.ok) {
                let message = await resp.json();
                user.token = message.data.jwt;
                this.storeUser(user);
                result = true;
            } else {
                await this.unSetUser().then(() => {
                    this.user = null;
                    result = false;
                });
            }
        }
        return result;
    }

    public async requestPsChange(email: string, lan: string) {
        const body = {
            email: email,
            lan: lan
        };

        const response = await fetch(Urls('psRestCodeUrl'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(body)
        });

        if (response.ok) {
            const message = await response.json();
            return message;
        } else {
            return {
                message: null,
                error: response.statusText
            };
        }
    }

    public async resetPs(data: any) {
        const body = {
            email: data.email,
            code: data.code,
            password: data.password,
        };

        const response = await fetch(Urls('resetPsUrl'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(body)
        });

        if (response.ok) {
            let message = await response.json();
            return message;
        } else {
            return {
                message: null,
                error: response.statusText
            };
        }
    }

    public async fetchAUser(id: string) {
        let resp = await fetch(Urls('getUser', id), {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        });

        if (resp.ok) {
            let message = await resp.json();
            return {
                message: message,
                error: null
            };
        } else {
            return {
                message: null,
                error: resp.statusText
            };
        }
    }

    public async updateUser(info: any) {
        let result = null;
        let user: User;

        const value = localStorage.getItem('user');

        if (value) {
            user = JSON.parse(value);
            let resp = await fetch(`${Urls('updateUserUrl', user.id + '')}`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token,
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(info)
            });

            if (resp.ok) {
                await this.fetchUser(user.token).then(_ => {
                    result = true;
                });
            }
        }

        return result;
    }
}

export default AuthServ;
